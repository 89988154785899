import { render, staticRenderFns } from "./OrdersFBMAdvancedCreationPage.vue?vue&type=template&id=392a9861&scoped=true"
import script from "./OrdersFBMAdvancedCreationPage.vue?vue&type=script&lang=js"
export * from "./OrdersFBMAdvancedCreationPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "392a9861",
  null
  
)

export default component.exports