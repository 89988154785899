<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row d-flex">
        <DefaultCheckbox
          class="mt--1"
          :selectedNow="selectedNow"
          :dataValue="item.id"
        />

        <div class="table-card__item-number table-card__item-number--small">
          <router-link
              v-if="item.return_parcel && isAdmin"
              :to="$store.getters.GET_PATHS.problemsReturnGoodsUrl + '/' + item.return_parcel.id"
              class="d-flex justify-content-center">
            <TooltipTextHelper>
              <template slot="text">
                <ReturnIconSVG class="mr-1"/>
              </template>
              <template slot="title">
                      <span style="font-size: 14px; font-weight: bold">
                        Id #{{item.return_parcel.id}}
                      </span>
              </template>
            </TooltipTextHelper>
          </router-link>

          <v-popover
                  class="site-tooltip"
                  offset="5"
                  placement="top-left"
                  trigger="hover"
          >
            <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
            'fbm_orderSentInConso',
          ])"></div>
              <span>
                <LabelCircle v-if="!item.consolidation && item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.id"
                             :color="'blue'" :value="'C'" class="mr-8"/>
                <LabelCircle v-if="!item.consolidation && item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.id"
                             :color="'orange'" :value="'W'" class="mr-8"/>
                <LabelCircle v-if="!item.consolidation && item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_LABEL.id"
                             :color="'violet'" :value="'L'" class="mr-8"/>
                <LabelCircle v-if="item.consolidation"
                             :color="'brown'" :value="'C'" class="mr-8"/>
              </span>
            </div>
            <template slot="popover">
              <div class="status-tooltip">
                <template v-if="!item.consolidation && item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.id" >
                  <LabelCircle :color="'blue'" :value="'C'" class="mr-8"/> Order for Consolidation
                </template>
                <template v-if="!item.consolidation && item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.id" >
                  <LabelCircle :color="'orange'" :value="'W'" class="mr-8"/> Order to Warehouse
                </template>
                <template v-if="!item.consolidation && item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_LABEL.id" >
                  <LabelCircle :color="'violet'" :value="'L'" class="mr-8"/> Lable
                </template>
                <template v-if="item.consolidation">
                  <LabelCircle :color="'brown'" :value="'C'" class="mr-8"/> {{$t('fbm_orderSentInConso.localization_value.value')}}
                </template>
              </div>
            </template>
          </v-popover>
          <router-link style="color: #CD9E64;"
                  :to="$store.getters.GET_PATHS.ordersFBM + '/show/' + getOrderFBMTypeById(item.order_type_id).name + '/' + item.id"
                  class="d-flex justify-content-center">
            #{{item.id}}
          </router-link>
        </div>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('fbm_id.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              #{{item.id}}
              <div v-if="barcode">{{barcode}}</div>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('fbm_Created.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>
        </div>
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('fbm_Recepient.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item.recipient_contact_name"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('fbm_PayPalTransactions.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item"
                      :deep="'payment_transaction.transaction_id'"
              />
            </div>
          </div>

          <!--<div class="table-card__item-col custom-col custom-col&#45;&#45;50">-->
            <!--<div class="table-card__item-label">-->
              <!--{{$t('fbm_Lots.localization_value.value')}}-->
            <!--</div>-->
            <!--<div class="table-card__item-info">-->
              <!--<ValueHelper-->
                      <!--:value="item.lot_number"-->
              <!--/>-->
            <!--</div>-->
          <!--</div>-->
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('fbm_Ship.localization_value.value')}} $
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item.own_label ? '' : item.delivery_cost"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col ">
            <div class="table-card__item-label">
              {{$t('fbm_TrackingNumber.localization_value.value')}}
            </div>
            <div class="table-card__item-info">

              <TableTrackingLog
                  v-if="item.delivery_state.delivery_status"
                  :trackingLogItem="null"
                  :trackingStateEvent="item.delivery_state"
              />

<!--              <v-popover-->
<!--                      v-if="item.tracking_number"-->
<!--                      class="site-tooltip"-->
<!--                      offset="5"-->
<!--                      placement="top-left"-->
<!--                      trigger="hover"-->
<!--              >-->
<!--                          <span>-->
<!--                            <StatusIcoBtn-->
<!--                                    :type="'delivered'"-->
<!--                                    class="mr-1"-->
<!--                            />-->
<!--                          </span>-->
<!--                <template slot="popover">-->
<!--                  <div class="status-tooltip">-->
<!--                    <StatusIcoBtn :type="'delivered'" class="mr-2"/>-->
<!--                    Delivered-->
<!--                  </div>-->
<!--                </template>-->
<!--              </v-popover>-->
              <ValueHelper
                      :value="item.tracking_number"
              />
            </div>
          </div>

          <div v-if="!navTabs[FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name].active" class="table-card__item-col custom-col ">
            <div class="table-card__item-label">
              {{$t('fbm_DHLTracknumber.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <div class="table-row">
                <ValueHelper
                    :value="item.consolidation"
                    :deep="'user_tracking_number'"
                />
                <!--<v-popover-->
                <!--v-if="item.tracking_number"-->
                <!--class="site-tooltip"-->
                <!--offset="5"-->
                <!--placement="top-left"-->
                <!--trigger="hover"-->
                <!--@click.native="showStatusOrderDetailsPopup"-->
                <!--&gt;-->
                <!--<span>-->
                <!--<StatusIcoBtn-->
                <!--:type="'delivered'"-->
                <!--class="mr-1"-->
                <!--/>-->
                <!--</span>-->
                <!--<template slot="popover">-->
                <!--<div class="status-tooltip">-->
                <!--<StatusIcoBtn :type="'delivered'" class="mr-2"/>-->
                <!--Delivered-->
                <!--</div>-->
                <!--</template>-->
                <!--</v-popover>-->

                <!--<ValueHelper-->
                <!--:value="item.tracking_number"-->
                <!--/>-->
              </div>
            </div>
          </div>

          <div class="table-card__item-row custom-row"
               v-if="_.has(currentPermissions, PERMISSIONS.ORDER_EXTERNAL_EXPRESS_POSHTA)"
          >
            <div class="table-card__item-col custom-col">
              <div class="table-card__item-label">
                {{$t('express_ExpressPoshtaTracking.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
              <span
                  v-if="item.express_poshta_tracking_number"
                  @click="$emit('expressPoshtaTracking')"
              >
                {{item.express_poshta_tracking_number}}
              </span>
                <template v-else>—</template>
              </div>
            </div>
          </div>

          <div v-if="!navTabs[FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name].active && !navTabs[FBM_ORDER_TYPES.FBM_ORDER_LABEL.name].active"
               class="table-card__item-col custom-col custom-col">
            <div class="table-card__item-label">
              Conso system
            </div>
            <div class="table-card__item-info">
              <template v-if="item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.id">
                <a :href="'https://expressposhta.com/search/' + item.system_tracking_number" target="_blank" class="table-link btn-style">
                  {{item.system_tracking_number}}
                </a>
              </template>
              <template v-else>—</template>
            </div>
          </div>

          <div v-if="!navTabs[FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name].active && !navTabs[FBM_ORDER_TYPES.FBM_ORDER_LABEL.name].active"
               class="table-card__item-col custom-col custom-col">
            <div class="table-card__item-label">
              Conso system
            </div>
            <div class="table-card__item-info">
              <template v-if="item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.id">
                <a :href="'https://expressposhta.com/search/' + item.system_tracking_number" target="_blank" class="table-link btn-style">
                  {{item.system_tracking_number}}
                </a>
              </template>
              <template v-else>—</template>
            </div>
          </div>

          <div v-if="!navTabs[FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name].active" class="table-card__item-col custom-col custom-col">
            <div class="table-card__item-label">
              {{$t('fbm_ConsoID.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <template v-if="item.consolidation !== null">
                <LinkButton
                    class="table-link btn-style"
                    :value="item.consolidation.id"
                    :link="(isAdmin ? $store.getters.GET_PATHS.ordersConsolidationEditing : $store.getters.GET_PATHS.ordersConsolidationDetail) + '/' +
                    getConsolidationTypeByDestinationId(item.consolidation.consolidationDestination.id) + '/' + item.consolidation.id"
                />
              </template>
              <template v-else>—</template>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col">
            <div class="table-card__item-label">
              {{$t('fbm_WasSentAt.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="isStatusComplete(item.status) ? item.delivery_date : ''"
                      :date="true"
                      :dateType="'DD MMM, YYYY'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col"
               v-if="!navTabs[FBM_ORDER_TYPES.FBM_ORDER_LABEL.name].active && (isUserPrepayment || isAdmin)">
            <div class="table-card__item-label">
              {{$t('fbm_PrepaidTotal.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item.prepay_delivery_cost"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col"
               v-if="!navTabs[FBM_ORDER_TYPES.FBM_ORDER_LABEL.name].active && (isUserPrepayment || isAdmin)">
            <div class="table-card__item-label">
              {{$t('fbm_Paid.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TooltipTextHelper>
                <template slot="text">
                  <StatusIcoBtn :type="'active'" class="mr-1" v-if="item.prepay === 1"/>
                  <StatusIcoBtn :type="'cancel-red'" class="mr-1" v-else/>
                </template>
                <template slot="title">
                  <span v-if="item.prepay === 1">{{$t('fbm_paidYes.localization_value.value')}}</span>
                  <span v-else>{{$t('fbm_paidNo.localization_value.value')}}</span>
                </template>
              </TooltipTextHelper>
            </div>
          </div>
        </div>

        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i"
               v-if="!(isCurrentUserRoleUser && isStatusComplete(item.status)) && !(isCurrentUserRoleUser && item.prepay === 1)">
            <LinkButton
                    :value="$t('common_edit.localization_value.value')"
                    :type="'edit'"
                    :link="$store.getters.GET_PATHS.ordersFBM + '/' + getOrderFBMTypeById(item.order_type_id).name + '/' + item.id"
            />
          </div>
          <div class="table-card__item-btn-i"
               v-if="!(isCurrentUserRoleUser && isStatusComplete(item.status)) && !(isCurrentUserRoleUser && item.prepay === 1) && !item.has_active_customization">
            <LinkButton
                    :value="$t('common_delete.localization_value.value')"
                    :type="'delete'"
                    @click.native="$emit('deleteOrder', item.id)"
            />
          </div>
          <div class="table-card__item-btn-i">
            <LinkButton
                    :value="$t('common_copy.localization_value.value')"
                    :type="'copy'"
                    @click.native="$emit('copyOrder', item.id)"
            />
          </div>
          <div class="table-card__item-btn-i"
               v-if="(item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_LABEL.id || isAdmin) &&
                                                isStatusComplete(item.status) && item.file_entity.length">
            <LinkButton
                    :value="$t('fbm_getLabelFBM.localization_value.value')"
                    :type="'dog'"
                    @click.native="$emit('getLabelFBM')"
            />
          </div>
          <div class="table-card__item-btn-i"
               v-if="(item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_LABEL.id) &&
                                                isStatusComplete(item.status)">
            <LinkButton
                :value="$t('fbm_reloadLabelFBM.localization_value.value')"
                :type="'dog'"
                @click.native="$emit('reloadLabelFBM')"
            />
          </div>
          <div class="table-card__item-btn-i"
               v-if="isStatusComplete(item.status) && !item.return_parcel && isAdmin">
            <LinkButton
                    :value="$t('fbm_Return.localization_value.value')"
                    :type="'return'"
            />
          </div>
          <div class="table-card__item-btn-i">
            <LinkButton
                :value="$t('common_termalPrint.localization_value.value')"
                :type="'print'"
                @click.native="$emit('thermalPrint', {id: [item.id]})"
            />
          </div>
          <div class="table-card__item-btn-i"
               v-if="item.can_stop_order">
<!--               v-if="(item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.id || item.order_type_id === FBM_ORDER_TYPES.FBM_ORDER_LABEL.id) &&-->
<!--                item.consolidation !== null &&-->
<!--                !isStatusStop(item.status) &&-->
<!--                !isGlobal24Table(item)"-->
            <LinkButton
                :value="$t('common_stop.localization_value.value')"
                :type="'pickup'"
                @click.native="$emit('stopOrderFBM')"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import LabelCircle from "../../../../../../../coreComponents/LabelCircle/LabelCircle";
  // import StatusIcoBtn from "../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import {FBMMixinsHelper} from "../../../../../../../../mixins/FBMMixins/FBMMixins";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import {FBM_ORDER_TYPES} from "../../../../../../../../staticData/staticVariables";
  import TableTrackingLog from "@/components/coreComponents/TableComponents/TableTrackingLog/TableTrackingLog";
  import {consolidationMixin} from "../../../../../../../../mixins/consolidationMixins/consolidationMixin";
  import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
  import ReturnIconSVG from '../../../../../../../../../public/img/UI-group/return-icon.svg?inline'
  import StatusIcoBtn from "@/components/UI/status/StatusIcoBtn/StatusIcoBtn";

  export default {
    name: "OrdersFBMTableUserTableMobile",
    components: {
      StatusIcoBtn,
      TooltipTextHelper, TableTrackingLog, ValueHelper, LinkButton,
      // StatusIcoBtn,
      LabelCircle,
      DefaultCheckbox,
      ReturnIconSVG,
    },

    mixins: [FBMMixinsHelper, consolidationMixin],

    props: {
      item: Object,
      navTabs: Object,
      selectedNow: Boolean,
      barcode: [Boolean, String],
    },

    data() {
      return {
        show: false,
        FBM_ORDER_TYPES: FBM_ORDER_TYPES,
      }
    },

  }
</script>

<style scoped>

</style>
