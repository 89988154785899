import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {PersonalInformation} from "../../../globalModels/PersonalInformation";
import {Delivery} from "../../../globalModels/Delivery";
import {ProformProduct} from "../../../globalModels/ProformProduct";
import {Product} from "../../../globalModels/Product";
import {Dimensions} from "../../../globalModels/Dimensions";
import _ from 'lodash'
import {User} from "../../../globalModels/User";
import {Order} from "../../../globalModels/Order";
import {VatNumber} from "../../../globalModels/VatNumber";
import {Files} from "../../../globalModels/Files";
import {ProductForCell} from "../../../globalModels/ProductForCell";
import {ProductCells} from "../../../globalModels/ProductCells";


export function OrderFBM() {

  this.checkValid = checkValid

  this.data = {
    Order: new Order(),
    User: new User(),

    isUserLabel: false,
    trackUserLabel: '',
    UserLabel:  new Files({
      maxCountFiles: 1,
      maxSizeFiles: 3,
    }),

    consolidation: null,
    consolidationDestination: null,

    transactionAddress: 'none',
    transactionAddressTypes: {
      none: 'none',
      paypal: 'paypal',
      etsy: 'etsy',
    },

    // SendFrom: new PersonalInformation(),
    sendFrom: null,

    /**
     * Default Personal /// none == Personal
     */
    Personal: new PersonalInformation(),
    PersonalEtsy: new PersonalInformation(),

    /**
     * Default DeliveryPayPal /// none == DeliveryPayPal
     */
    DeliveryPayPal: new Delivery(),
    DeliveryEtsy: new Delivery(),

    comment: '',

    ProformProducts: [new ProformProduct()],
    proformProductsDeletedIds: [],

    Products: [new Product()],

    ProductForCell: new ProductForCell(),
    productsForCellArray: [new ProductForCell()],

    VatNumber: new VatNumber(),

    lotNumber: '',

    selectedDimensions: null,
    Dimensions: new Dimensions(),
    AdminDimensions: new Dimensions(),

    orderSizes: [],

    isInsurance: false,
    insuranceAmount: '',
    insuranceCost: '',

    insuranceFromDeliveryCompany: '0',

    useEasyPost: false,
    shippingCompanies: [],
    shippingAPIPrices: [],

    shippingPartnerType: {},
    shippingPartner: {},
    shippingAPIPrice: {},

    possibleSaturdayDelivery: false,

    changeCarrier: false,

    packagingType: '',
    packagingTypes: {},
    giftPackagingType: '',
    giftPackagingTypes: {},
    giftPackagingPrice: '',

    packagingAdminType: '',
    packagingAdminPrice: '',
    giftPackagingAdminType: '',
    giftPackagingAdminPrice: '',

    packagingCost: '',

    signFromRecipient: false,

    giftMassage: '',

    giftPaper: '',

    fileGift: null,

    orderPrice: '',

    shippingCost: '',
    skladUsaFee: '',
    invoiceTotalAmount: '',

    receivedAt: null,

    visibleHSCode: false,

    idReturned: null,

    marketplaces: [
      {
        name: 'Etsy',
      },
      {
        name: 'Ebay',
      },
      {
        name: 'Amazon.com',
      },
      {
        name: 'Amazon UK and EU',
      },
    ],
    marketplace: {},
    IOSS: {},
    transactionAmount: 0,
    customsClearance: 0,

    price: 0,
    insurancePrice: 0,
    calcOrderProcessFee: 0,
    productsExtraFee: 0,

    vatNumber: '',
    vatNumberChoose: false,
    visibleVatNumber: true,
    isVatNumberByCountry: true,
    vatNumberFile: new Files({
      maxCountFiles: 1,
      maxSizeFiles: 3,
    }),

    consolidationOrderUnionId: null,
    consolidationOrderUnionTrack: null,

    consolidationUnion: null,

    regions: [],

    trackingNumberRefund: 0,

    ProductPhoto: new Files({
      maxCountFiles: 20,
      maxSizeFiles: 3
    }),

    sendFromRegion: '',


    isEngraving: false,
    engravingAdminCheck: false,
    engravingAdminDownloaded: false,

    // engravingLength: 0,
    // engravingWidth: 0,
    // engravingCount: 0,
    // engravingPrice: 0,
    engravingTime: 0,
    // engravingCustomization: null,
    EngravingFile: new Files({
      maxCountFiles: 5,
      maxSizeFiles: 3
    }),

    Engravings: [new CalculatorEngraving()],

    TShirtPrinting: new TShirtPrinting(),
    Embroidery: new Embroidery(),
    LeatherStamp: new LeatherStamp(),
    PhotoPrint: new PhotoPrint(),
    UVPrinting: new UVPrinting(),

    //expressposhta fields
    expressTrack: '',
    npTrack: '',

    optionsCustomizationFee: [],
    selectedCustomizationFee: null,
    otherCustomizationFee: '',
    isUserCustomizationFee: false,

    easyOrderType: false,
    userTrackingNumber: '',

    orderFbmFees: [],
  }

  this.validation = {
    trackUserLabel: false,
    sendFrom: false,
    comment: false,
    lotNumber: false,
    insuranceAmount: false,
    insuranceCost: false,
    giftMassage: false,
    orderPrice: false,
    marketplace: false,
    IOSS: false,
    transactionAmount: false,
    vatNumber: false,
    vatNumberChoose: false,
    receivedAt: false,
    consolidationOrderUnionId: false,
    consolidationOrderUnionTrack: false,

    engravingLength: false,
    engravingWidth: false,
    engravingCount: false,
    engravingTime: false,

    ProformProducts: false,
    productsForCellArray: false,
  }

  this.validationTranslate = {
    trackUserLabel: '',
    sendFrom: '',
    comment: '',
    lotNumber: '',
    insuranceAmount: '',
    insuranceCost: '',
    giftMassage: '',
    orderPrice: '',
    marketplace: '',
    IOSS: '',
    transactionAmount: '',
    vatNumber: '',
    vatNumberChoose: '',
    receivedAt: '',
    consolidationOrderUnionId: '',
    consolidationOrderUnionTrack: '',

    engravingTime: '',
    engravingLength: '',
    engravingWidth: '',
    engravingCount: '',

    ProformProducts: '',
    productsForCellArray: '',
  }

  this.validationTxt = {
    trackUserLabel: false,
    sendFrom: false,
    comment: false,
    lotNumber: false,
    insuranceAmount: false,
    insuranceCost: false,
    giftMassage: false,
    orderPrice: false,
    marketplace: false,
    IOSS: false,
    transactionAmount: false,
    vatNumber: false,
    vatNumberChoose: false,
    receivedAt: false,
    consolidationOrderUnionId: false,
    consolidationOrderUnionTrack: false,

    engravingTime: false,
    engravingLength: false,
    engravingWidth: false,
    engravingCount: false,

    ProformProducts: false,
    productsForCellArray: false,
  }

}

/*****************   SETTERS   ******************/

import "./OrderFBMSetters"



/*****************   GETTERS   ******************/

import "./OrderFBMGetters"


/*******************   FUNCTIONS   *************/

OrderFBM.prototype.addEngraving = function () {
  this.data.Engravings.push(new CalculatorEngraving())
  return this.data.Engravings.length - 1
}

OrderFBM.prototype.removeEngraving = function (index) {
  this.data.Engravings.splice(index, 1)
  return this.data.Engravings.length - 1
}

OrderFBM.prototype.addProformProduct = function (itemInit = false) {
  this.data.ProformProducts.push(new ProformProduct)

  if(itemInit){
    this.copyProformTypesToNewProduct(
      this.data.ProformProducts[this.data.ProformProducts.length - 1]
    )
  }

  return this.data.ProformProducts.length - 1
}
OrderFBM.prototype.copyProformTypesToNewProduct = function (newProduct) {
  Object.keys(this.data.ProformProducts[0].product.proformData.proformTypes).map(item => {
    newProduct.product.proformData.proformTypes[item] = {
      id: item,
      typeName: this.data.ProformProducts[0].product.proformData.proformTypes[item].typeName,
      typeValue: '',
      typeOptions: [{}],
    }
  })
}

OrderFBM.prototype.removeProformProduct = function (index) {
    this.data.proformProductsDeletedIds.push(this.data.ProformProducts[index].product.proformEntityId)
  this.data.ProformProducts.splice(index, 1)
}

OrderFBM.prototype.addProduct = function () {
  this.data.Products.push(new Product)
  return this.data.Products.length - 1
}

OrderFBM.prototype.removeProduct = function (index) {
  this.data.Products.splice(index, 1)
}

OrderFBM.prototype.resetProduct = function () {
  this.data.Products = [new Product]
}

OrderFBM.prototype.removeVatNumberFile = function (id) {
  let i = -1
  _.findIndex(this.data.vatNumberFile.getDownloadFiles(), function (item, index) {
    if (item[id] !== undefined) i = index
  });

  this.data.vatNumberFile.removeDownloadFile(i)
}

OrderFBM.prototype.setExpressVatNumberFile = function(val) {
  this.data.vatNumberFile.pushDownloadFiles(val)
}


OrderFBM.prototype.addProductForCells = function (product = false, cells = false, productEntity = false) {
  let productForCell = new ProductForCell(product, false, false, productEntity?.count_scanned_kyiv)
  if(cells.length > 0){
    productForCell.setCellsByType(cells)
  }
  this.data.productsForCellArray.push(productForCell)
  return this.data.productsForCellArray.length - 1
}

OrderFBM.prototype.removeProductForCells = function (array, index) {
  array.splice(index, 1)
}


OrderFBM.prototype.sortCellArrayByStorageCount = function (userCellArray) {
  let sortByStorageCount = _.sortBy(userCellArray, function (item) {
    return 0 - item.data.storageCount
  })
  return sortByStorageCount
}

OrderFBM.prototype.createProductModelAndCellsArray = function (productEntity, item) {
  /**
   * create and Product model
   * @type {Product}
   */
  let newProduct = new Product()
  newProduct.setProduct(productEntity.product, {
    id: productEntity.product.id,
    name: productEntity.product.name,
    count: productEntity.item_quantity,
    productModelId: item.id,
    description: productEntity.item_description,
    price: productEntity.item_price,
    hs_code: productEntity.hs_code,
  })

  let cells = []
  productEntity.cells.map(cellItem => {
    let cellOptions = productEntity.cells.map(cell => {return cell.cell})
    let cell = new ProductCells(cellOptions, cellItem.cell, false, cellItem.item_quantity)
    cells.push(cell)
  })

  let existCell = cells.length > 0
  /**
   * Map product -> items_in_storage
   * if exist cell in storage and not selected =>
   * add this cell to productsForCellArray
   */
  productEntity.product?.items_in_storage.map(productInWarehouse => {
    if(productInWarehouse.damage) return

    /**
     * if this cell will be selected and saved
     * go to next productInWarehouse
     */
    if(existCell){
      let findExistCell = _.find(cells, function (findItem) {
        return findItem.data.cellSelected?.id === productInWarehouse.cell_id
      })
      /**
       * set storageCount from items_in_storage[index]
       */
      if(findExistCell) {
        findExistCell.data.storageCount = productInWarehouse.quantity
        return
      }
    }

    /**
     * set cellOptions in array productEntity.product?.items_in_storage
     */
    let cellOptions = _.filter(productEntity.product?.items_in_storage, function(cell) {
      if(productInWarehouse?.cell?.mixed && cell?.cell?.mixed) return cell.cell
      if(!productInWarehouse?.cell?.mixed && (!cell?.cell || !cell?.cell?.mixed)) return cell.cell
    })
    cellOptions = cellOptions.map(item => {return item.cell})

    let cell = new ProductCells(
      cellOptions,
      productInWarehouse.cell,
      productInWarehouse.quantity,
      false
    )

    cells.push(cell)
  })

  return {newProduct, cells, existCell}
}


OrderFBM.prototype.addProductCountWithoutCell = function (product, productForCell) {
  product.product.items_in_storage.map(productInWarehouse => {
    if(!productInWarehouse.cell && productInWarehouse.cell_id) return
    if(productInWarehouse.damage) return

    if(!productInWarehouse.cell) {
      productForCell.data.withoutCellDeclaredCounts = parseInt(productForCell.data.withoutCellDeclaredCounts) + parseInt(productInWarehouse.quantity)
    }
  })
}

OrderFBM.prototype.addProductExistCountWithoutCell = function (product, productForCell) {
  product.cells.map(cell => {
    if(!cell.cell && cell.cell_id) return

    if(!cell.cell) {
      productForCell.data.withoutCellEndCounts = parseInt(productForCell.data.withoutCellEndCounts) + parseInt(cell.item_quantity)
    }
  })
}

OrderFBM.prototype.productsDataGeneration = function (array, withCells = false) {
  let data = []
  array.map(item => {
    let product = item.data.Product.product
    let productId = product?.id

    if(!productId) return

    let itemData = {
      "product_id": productId,
      "hs_code": item.data.Product.getHsCode(),
      "item_quantity": item.data.Product.getProductCount(),
      "item_description": item.data.Product.getProductDescription(),
      "item_price": parseFloat(item.data.Product.getProductPrice()) > 0 ? parseFloat(item.data.Product.getProductPrice()) : null,
      "id": item.data.Product.getProductModelId(),
      "count_scanned_kyiv": item.data.scannedInKievCount,
    }

    if(withCells){
      let cells = [],
        userCellArray = item.data.UserCellArray,
        mixedCellArray = item.data.MixedCellArray

      cells = [...cells, ...this.getCellsByArray(userCellArray, productId)]
      cells = [...cells, ...this.getCellsByArray(mixedCellArray, productId)]

      if(parseInt(item.data.withoutCellEndCounts) !== 0) {
        cells.push({
          "product_id": productId,
          "cell_id": null,
          "item_quantity": parseInt(item.data.withoutCellEndCounts),
        })
      }


      itemData["cells"] = cells
    }
    data.push(itemData)
  })
  return data
}

OrderFBM.prototype.getCellsByArray = function (array, productId) {
  let cells = []
  array.map(itemCell => {
    if(itemCell.data.cellCount > 0){
      cells.push({
        "product_id": productId,
        "cell_id": itemCell.data.cellSelected.id,
        "item_quantity": parseInt(itemCell.data.cellCount),
      })
    }
  })
  return cells
}










OrderFBM.prototype.setProductsDefault = function (val) {
  if(val === ''){
    this.data.productsDefault = [new Product()]
  }
}

OrderFBM.prototype.removeVatFile = function(id) {
  let index = _.findIndex(this.data.vatNumberFile.data.downloadFiles, id)
  this.data.vatNumberFile.removeDownloadFile(index)
}

OrderFBM.prototype.checkIsMarketplaceCompany = function (shippingPartnerId) {
  console.log(shippingPartnerId);
  // return shippingPartnerId === DPD_SHIPPING_COMPANY.id ||
  //   shippingPartnerId === POSTNL_SHIPPING_COMPANY.id ||
  //   shippingPartnerId === POST_AT_METHOD.id ||
  //   shippingPartnerId === POST_NL_VAT_METHOD.id ||
  //   shippingPartnerId === COLISSIMO_METHOD.id ||
  //   shippingPartnerId === DHL_WARENPOST_METHOD.id ||
  //   shippingPartnerId === DHL_PAKET_METHOD.id ||
  //   shippingPartnerId === GSL_METHOD.id ||
  //   shippingPartnerId === CORREOS_METHOD.id ||
  //   shippingPartnerId === DPD_VAT_METHOD.id

  if(shippingPartnerId === ROYAL_MAIL_COMPANY.id) return true

  return false
}

/*****************   VALIDATION   ******************/

import "./OrderFBMValidations"


/*********************************  PREPARE DATA  *********************************/

import "./OrderFBMPrepareData"
import {
  // COLISSIMO_METHOD, CORREOS_METHOD, DHL_PAKET_METHOD, DHL_WARENPOST_METHOD,
  // DPD_SHIPPING_COMPANY, DPD_VAT_METHOD, GSL_METHOD,
  // POST_AT_METHOD,
  // POST_NL_VAT_METHOD,
  // POSTNL_SHIPPING_COMPANY,
  ROYAL_MAIL_COMPANY
} from "@/staticData/staticVariables";
import {TShirtPrinting} from "@/components/modules/CalculatorEngravingModule/models/TShirtPrinting";
import {Embroidery} from "@/components/modules/CalculatorEngravingModule/models/Embroidery";
import {PhotoPrint} from "@/components/modules/CalculatorEngravingModule/models/PhotoPrint";
import {LeatherStamp} from "@/components/modules/CalculatorEngravingModule/models/LeatherStamp";
import {UVPrinting} from "@/components/modules/CalculatorEngravingModule/models/UVPrinting";
import {CalculatorEngraving} from "@/components/modules/CalculatorEngravingModule/models/CalculatorEngraving";
