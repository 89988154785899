import _ from "lodash";
import {FBM_ALL_STATUSES, FBM_ORDER_TYPES} from "../../../../staticData/staticVariables";
import {OrderFBM} from "./OrderFBM";
const moment = require('moment')

OrderFBM.prototype.setOrderId = function (val) {
  this.data.Order.setId(val)
}

OrderFBM.prototype.setTypeFBM = function (val) {
  this.data.Order.setType(_.find(FBM_ORDER_TYPES, {name: val}))
}

OrderFBM.prototype.setFBMTypeTransaction = function ({type, id}) {

  this.data.Order.setTransactionType(type)
  this.data.Order.setTransactionId(id)
}

OrderFBM.prototype.setUser = function (val) {
  this.data.User.setUser(val)
}

OrderFBM.prototype.setUserData = function (user) {
  console.log(user);

  if(!user?.id) return

  // let userInformation
  let data = this.data

  // if(user?.contacts){
  //   userInformation = _.first(user?.contacts)
  // } else {
  //   userInformation = user?.user_personal_contact
  // }

  data.User.setUser(user)

  // this.writingDataCurrentUser(userInformation)

}

OrderFBM.prototype.writingDataCurrentUser = function (userInformation) {
  let data = this.data

  data.Personal.setPersonalItem({
    personalName: userInformation.user_full_name,
    personalPhone: userInformation.phone.replace(/[^\d]/g, ''),
    personalCompany: userInformation.company_name,
  })

  data.DeliveryPayPal.setDeliveryItem({
    address: userInformation.address,
    city: userInformation.city,
    region: userInformation.region.name,
    country: {
      id: userInformation.country.id,
      label: userInformation.country.name,
    },
    zip: userInformation.zip
  })
}


OrderFBM.prototype.setComment = function (val) {
  this.data.comment = val;
}

OrderFBM.prototype.setLotNumber = function (val) {
  this.data.lotNumber = val;
}

OrderFBM.prototype.setIsInsurance = function (val) {
  this.data.isInsurance = val;
}

OrderFBM.prototype.setInsuranceAmount = function (val) {
  this.data.insuranceAmount = val;
}

OrderFBM.prototype.setInsuranceCost = function (val) {
  this.data.insuranceCost = val;
}

OrderFBM.prototype.setInsuranceFromDeliveryCompany = function (val) {
  this.data.insuranceFromDeliveryCompany = val;
}

OrderFBM.prototype.setGiftMassage = function (val) {
   this.data.giftMassage = val;
}

OrderFBM.prototype.setFileGift = function (val) {
  this.data.fileGift = val
}

OrderFBM.prototype.setShippingPartnerType = function (val) {
  this.data.shippingPartnerType = val;
}

OrderFBM.prototype.setShippingPartner = function (val) {
  this.data.shippingPartner = val;
}

OrderFBM.prototype.setShippingAPIPrice = function (val) {
  this.data.shippingAPIPrice = val;
}

OrderFBM.prototype.setPackagingType = function (val) {
  this.data.packagingType = val;
}

OrderFBM.prototype.setGiftPackagingType = function (val) {
  this.data.giftPackagingType = val;
}

OrderFBM.prototype.setPackagingAdminType = function (val) {
  this.data.packagingAdminType = val;
}

OrderFBM.prototype.setGiftPackagingAdminType = function (val) {
  this.data.giftPackagingAdminType = val;
}

OrderFBM.prototype.setPackagingAdminPrice = function (val) {
  this.data.packagingAdminPrice = val;
}

OrderFBM.prototype.setGiftPackagingPrice = function (val) {
  this.data.giftPackagingPrice = val;
}

OrderFBM.prototype.setGiftPackagingAdminPrice = function (val) {
  this.data.giftPackagingAdminPrice = val;
}

OrderFBM.prototype.setPackagingCost = function (val) {
  this.data.packagingCost = val;
}

OrderFBM.prototype.setSignFromRecipient = function (val) {
  this.data.signFromRecipient = val;
}

OrderFBM.prototype.setGiftPaper = function (val) {
  this.data.giftPaper = val;
}

OrderFBM.prototype.setOrderPrice = function (val) {
  this.data.orderPrice = val;
}

OrderFBM.prototype.setShippingCost = function (val) {
  this.data.shippingCost = val;
}

OrderFBM.prototype.setSkladUsaFee = function (val) {
  this.data.skladUsaFee = val;
}

OrderFBM.prototype.setInvoiceTotalAmount = function (val) {
  this.data.invoiceTotalAmount = val;
}

OrderFBM.prototype.setReceivedAt = function (val) {
  this.data.receivedAt = val;
}

OrderFBM.prototype.setShippingCompanies = function (val) {
  this.data.shippingCompanies = val;
}

OrderFBM.prototype.setShippingAPIPrices = function (val) {
  this.data.shippingAPIPrices = val;
}

OrderFBM.prototype.setPossibleSaturdayDelivery = function (val) {
  this.data.possibleSaturdayDelivery = val;
}

OrderFBM.prototype.setPackagingTypes = function (val) {
  this.data.packagingTypes = val;
}

OrderFBM.prototype.setGiftPackagingTypes = function (val) {
  this.data.giftPackagingTypes = val;
}

OrderFBM.prototype.setVisibleHSCode = function (val) {
  this.data.visibleHSCode = val
}

OrderFBM.prototype.setIdReturned = function (val) {
  this.data.idReturned = val
}

OrderFBM.prototype.setCalcOrderProcessFee = function (val) {
  this.data.calcOrderProcessFee = val
}

OrderFBM.prototype.setProductsExtraFee = function (val) {
  this.data.productsExtraFee = val
}

OrderFBM.prototype.setTrackingNumberRefund = function (val) {
  this.data.trackingNumberRefund = val
}

OrderFBM.prototype.setChangeCarrier = function (val) {
  this.data.changeCarrier = val
}


OrderFBM.prototype.setOrderFBMItem = function (val) {
  this.data.comment = val.comment
  this.data.lotNumber = val.lotNumber
  this.data.insuranceAmount = val.insuranceAmount
  this.data.insuranceCost = val.insuranceCost
  this.data.giftMassage = val.giftMassage
}

OrderFBM.prototype.setVatNumber = function (val) {
  this.data.vatNumber = val
}

// OrderFBM.prototype.setVisibleVatNumber = function (val) {
//   this.data.isVatNumberByCountry = val
// }

OrderFBM.prototype.setVisibleVatNumber = function (val, defaultVisible = true) {
  console.log(222222222);
  console.log(val, defaultVisible);
  this.data.isVatNumberByCountry = val
  if(!defaultVisible){
    this.data.visibleVatNumber = defaultVisible
  } else {
    this.data.visibleVatNumber = val
  }
}


OrderFBM.prototype.setTransactionInfo = function (val = false) {

  if(!val) return

  let FBM = this.data
  let Personal = this.data.Personal

  let transaction = null
  let transaction2 = null

  if(val.orderPrice){
    this.setOrderPrice(val.orderPrice)
  }

  if(val.fbmFromOrderExternal){
    transaction = val.fbmFromOrderExternal
    if(val?.orderExternal.payment_transaction_id !== null) {
      this.data.transactionAmount = val?.orderExternal?.transaction_net_amount
      this.data.Order.setIssetTransaction(true)
    }
  }
  if(val.fbmFromPaymentTransaction){
    transaction = val.fbmFromPaymentTransaction
    this.data.transactionAmount = val?.paymentTransaction?.warehouse_amount
    this.data.Order.setIssetTransaction(true)
  }

  if(val.fbmFromOrderExternal && val.fbmFromPaymentTransaction){
    transaction = val.fbmFromPaymentTransaction
    transaction2 = val.fbmFromOrderExternal
  }

  FBM.DeliveryPayPal.setDeliveryItem({
    address: transaction.recipient_address.substring(0,35),
    address2: transaction?.recipient_address_1 ? transaction.recipient_address_1.substring(0,35) : '',
    city: transaction.recipient_city,
    region: transaction.recipient_region,
    country: transaction.recipient_country ? {
      id: transaction.recipient_country.id,
      label: transaction.recipient_country.name
    } : null,
    zip: transaction.recipient_zip_code
  })


  this.data.Personal.setPersonalItem({
    personalName: transaction['recipient_contact_name'],
    personalPhone: transaction['recipient_phone_number'],
    personalEmail: transaction['recipient_email'],
  })

  if(transaction2){
    FBM.DeliveryEtsy.setDeliveryItem({
      address: transaction2.recipient_address.substring(0,35),
      address2: transaction2?.recipient_address_1 ? transaction2.recipient_address_1.substring(0,35) : '',
      city: transaction2.recipient_city,
      region: transaction2.recipient_region,
      country: {
        id: transaction2.recipient_country.id,
        label: transaction2.recipient_country.name
      },
      zip: transaction2.recipient_zip_code
    })

    this.data.PersonalEtsy.setPersonalItem({
      personalName: transaction2['recipient_contact_name'],
      personalPhone: transaction2['recipient_phone_number'],
      personalEmail: transaction2['recipient_email'],
    })

    FBM.transactionAddress = FBM.transactionAddressTypes.paypal
  }

  Personal.setPersonalItem({
    personalName: transaction.recipient_contact_name,
    personalPhone: transaction?.recipient_phone_number ? transaction?.recipient_phone_number.replace(/[^\d]/g, '') : '',
    personalEmail: transaction.recipient_email,
    personalCompany: transaction.recipient_company_name,
  })

  // let contacts = val.payment_transaction_detail.payment_transaction_contact,
  //   personal = this.express.personal,
  //   delivery = this.express.delivery
  //
  // personal.setPersonalItem({
  //   personalName: contacts.full_name,
  //   personalPhone: contacts.phone ? contacts.phone.replace(/[^\d]/g, '') : '',
  //   personalEmail: contacts.email,
  // })
  //
  // this.express.userId = val.user.id
  // this.express.user = val.user
  //
  //
  // delivery.setDeliveryItem({
  //   address: contacts.address,
  //   city: contacts.city,
  //   region: contacts.state,
  //   country: contacts.countryInstance ? {
  //     id: contacts.countryInstance.id,
  //     label: contacts.countryInstance.name,
  //   } : '',
  //   zip: contacts.zip_code,
  // })

}

OrderFBM.prototype.setVatNumberFile = function(val) {
  this.data.vatNumberFile.pushDownloadFiles(val)
}

OrderFBM.prototype.setItem = function (val, isAdmin = false, show = false, consolidationDestinationData = false) {

  let item = val.orderFbmInstance,
    proformProducts = this.data.ProformProducts,
    proform = val.proformData,
    rate = val.rate,
    proformEntities = item.proform_entities,
    productEntities = item.product_entities,
    // labelFileId = val?.files?.orderFbmPdfLabelFileType,
    giftFileId = val?.files?.orderFmbGiftFile

  // let foundLabelFile = null
  let foundGiftFile = null

  // if (labelFileId && item?.file_entity?.length) {
  //   foundLabelFile = item.file_entity.find(findItem => {
  //     return findItem.id === labelFileId
  //   })
  // }

  if (giftFileId && item?.file_entity?.length) {
    foundGiftFile = item.file_entity.find(findItem => {
      return findItem.id === giftFileId
    })
  }

  this.data.Order.setOrderOBJ(val)

  this.data.User.setUser(item['user'])

  /**
   * set Customization Fee and list
   */
  this.data.optionsCustomizationFee = val.customization_fee_list
  if(item.customization_fee_model?.value) {
    this.data.selectedCustomizationFee = item.customization_fee_model
    this.data.isUserCustomizationFee = true
    if(item.customization_fee_model?.label && item.customization_fee_model.label.indexOf('Other') > -1) {
      this.data.otherCustomizationFee = item.customization_fee_model?.value
    }
  }

  if(item?.own_label) {
    this.data.isUserLabel = true
    this.data.trackUserLabel = item?.tracking_number
  }

  this.data.consolidation = item.consolidation
  if(this.data.consolidation && consolidationDestinationData) {
    this.data.consolidationDestination = consolidationDestinationData.find(destinationItem => {
      return destinationItem.id === this.data.consolidation?.consolidation_destination_id
    })
  }

  // this.data.Order.setStatus(_.find(FBM_STATUSES, {value: item['status']}))

  this.data.Order.setItem({
    id: item['id'],
    status: _.find(FBM_ALL_STATUSES, {value: item['status']}),
    oldStatus: _.find(FBM_ALL_STATUSES, {value: item['status']}),
    type: _.find(FBM_ORDER_TYPES, {id: item['order_type_id']}),
    trackingNum: item['tracking_number'],
    warehouse: item['warehouse_storage'],
    isWasBeenCreateLabel: item.label_file,
    fileLabel: item.label_file,
    isDraft: item['in_draft'] === 1,
    createdAt: item['created_at'],
    transactionType: item?.payment_transaction?.payment_system ? item.payment_transaction.payment_system : null,
    transactionId: item?.payment_transaction?.transaction_id ? item.payment_transaction.transaction_id : null
  })

  if (foundGiftFile) {
    this.setFileGift(foundGiftFile)
  }

  this.data.Order.setHistoryOfChanged(item?.activityUUID)

  this.setTrackingNumberRefund(Number(item?.tracking_number_refund))

  this.setConsolidationOrderUnionId(item['consolidation_order_union_id'])
  this.setConsolidationOrderUnionTrack(item?.consolidation_order_union?.tracking_number ? item.consolidation_order_union.tracking_number : null)

  this.setConsolidationUnion(item['consolidation_union'])

  this.setIdReturned(item['return_parcel'] ? item['return_parcel'].id : '')
  this.setComment(item['comment'] ? item['comment'] : '')
  this.setLotNumber(item['lot_number'])
  this.setIsInsurance(parseFloat(item['insurance_amount']) !== 0)
  this.setInsuranceAmount(item['insurance_amount'])
  this.setInsuranceFromDeliveryCompany(item['insurance'] && item.insurance?.price ? parseFloat(item.insurance.price).toFixed(2) : '0')
  this.setGiftMassage(item['gift_message'] ? item['gift_message'] : '')
  this.setGiftPaper(item['gift_paper'])   //packing_type - packing_prices для админа

  this.setSkladUsaFee(item['order_admin_fee'] ? item['order_admin_fee'] : '')
  this.setInvoiceTotalAmount(item['payed_invoice'] ? item['payed_invoice'] : '')

  this.setChangeCarrier(item?.dont_change_carrier)

  this.setShippingPartner(item['delivery_method'])
  if(_.has(item['delivery_method'], 'shipping_company')){
    this.setShippingPartnerType(item['delivery_method']['shipping_company'])
  }
  if(_.has(rate, 'driver_delivery_method_id')){
    this.setShippingAPIPrice(rate)
    this.setShippingAPIPrices([rate])
  }

  if(_.has(rate, 'insuranceCost') && Number(rate['insuranceCost']) > 0){
    this.setInsuranceCost(rate['insuranceCost'])
  }


  this.setPackagingType(item['packing_type'])
  this.setPackagingCost(item['packaging_cost'])

  if(isAdmin){
    this.setPackagingTypes(val['packing_types'])
    this.setGiftPackagingTypes(val['gift_packing_types'])
    this.setPackagingAdminType(item['admin_packing_type'] ? item['admin_packing_type'] : '')
    this.setGiftPackagingAdminType(item['order_gift_packing']?.packing_type || null)
    this.setGiftPackagingAdminPrice(item['order_gift_packing']?.packing_prices || null)
    this.setPackagingAdminPrice(item['packing_prices'])
  }
  else if (item['order_gift_packing']) {
    this.setGiftPackagingType(item['order_gift_packing']?.packing_type || null)
    this.setGiftPackagingPrice(item['order_gift_packing']?.packing_prices || null)
  }

  this.setSignFromRecipient(item['require_signature'])
  this.getPossibleSaturdayDelivery(item['saturday_delivery'] === 1)

  this.setShippingCost(item['delivery_cost'])

  if(isAdmin){
    if(_.has(val, 'order_fees_list'))
      this.setOrderPrice(_.find(val['order_fees_list'], {id: item.order_fee_id}))
  } else {
    this.setOrderPrice(item['order_process_fee'])
  }

  // item['status'] !== FBM_STATUSES.FBM_COMPLETED_STATUS.value &&
  if(!item['delivery_date'] && !show){
    this.setReceivedAt(moment(new Date()).format('YYYY-MM-DD'))
  } else {
    this.setReceivedAt(item['delivery_date'] ? item['delivery_date'] : '')
  }

  // if(item.engraving) {
  //   if(item.engraving?.need_engraving) {
  //     this.data.engravingAdminCheck = item.engraving.complete === 1
  //   }
  //   this.data.isEngraving = Boolean(item.engraving?.need_engraving)
  //   this.data.engravingLength = item.engraving?.length
  //   this.data.engravingWidth = item.engraving?.width
  //   this.data.engravingCount = item.engraving?.count
  //   this.data.engravingPrice = parseFloat(item.engraving?.cost || 0).toFixed(2)
  //
  //   if(item.engraving?.customization_offer) {
  //     this.data.engravingCustomization = item.engraving?.customization_offer?.offer
  //   }
  // }

  if(item.engravings.length > 0) {
    if(item.engravings[0]?.need_engraving) {
      this.data.engravingAdminCheck = item.engravings[0]?.complete === 1
    }
    this.data.isEngraving = Boolean(item.engravings[0]?.need_engraving)

    item.engravings.map((engravingItem, engravingIndex) => {
      if(!this.data.Engravings[engravingIndex]) {
        this.addEngraving()
      }
      this.data.Engravings[engravingIndex].data.id = engravingItem?.id
      this.data.Engravings[engravingIndex].data.Dimensions.data.dimensionLength = engravingItem?.length
      this.data.Engravings[engravingIndex].data.Dimensions.data.width = engravingItem?.width
      this.data.Engravings[engravingIndex].data.count = engravingItem?.count
      this.data.Engravings[engravingIndex].data.engravingPrice = parseFloat(engravingItem?.cost || 0).toFixed(2)

      if(engravingItem?.customization_offer) {
        this.data.Engravings[engravingIndex].data.customization = engravingItem?.customization_offer?.offer
      }

    })

  }

  if(item.embroidery) {
    if(item.embroidery?.need_embroidery) {
      this.data.engravingAdminCheck = item.embroidery.complete === 1
    }
    this.data.Embroidery.data.isEmbroidery = Boolean(item.embroidery?.need_embroidery)
    this.data.Embroidery.data.stitches = item.embroidery?.number_of_stitches
    this.data.Embroidery.data.quantity = item.embroidery?.quantity
    this.data.Embroidery.data.resultCost = parseFloat(item.embroidery?.cost || 0).toFixed(2)

    if(item.embroidery?.customization_offer) {
      this.data.Embroidery.data.customization = item.embroidery?.customization_offer?.offer
    }
  }

  if(item.customization_print_u_v) {
    if(item.customization_print_u_v?.need_print_uv) {
      this.data.engravingAdminCheck = item.customization_print_u_v.complete === 1
    }
    this.data.UVPrinting.data.isUVPrinting = Boolean(item.customization_print_u_v?.need_print_uv)
    this.data.UVPrinting.data.Dimensions.data.dimensionLength = item.customization_print_u_v?.length
    this.data.UVPrinting.data.Dimensions.data.width = item.customization_print_u_v?.width
    this.data.UVPrinting.data.count = item.customization_print_u_v?.quantity
    this.data.UVPrinting.data.resultCost = parseFloat(item.customization_print_u_v?.cost || 0).toFixed(2)

    if(item.customization_print_u_v?.customization_offer) {
      this.data.UVPrinting.data.customization = item.customization_print_u_v?.customization_offer?.offer
    }
  }

  if(item.tshirt_painting) {
    if(item.tshirt_painting?.need_painting) {
      this.data.engravingAdminCheck = item.tshirt_painting.complete === 1
    }
    this.data.TShirtPrinting.data.isTShirtPrinting = Boolean(item.tshirt_painting?.need_painting)
    this.data.TShirtPrinting.data.quantity = item.tshirt_painting?.quantity
    this.data.TShirtPrinting.data.color = item.tshirt_painting?.color_type
    this.data.TShirtPrinting.data.quality = item.tshirt_painting?.material_type
    this.data.TShirtPrinting.data.resultCost = parseFloat(item.tshirt_painting?.cost || 0).toFixed(2)

    if(item.tshirt_painting?.customization_offer) {
      this.data.TShirtPrinting.data.customization = item.tshirt_painting?.customization_offer?.offer
      if(item.tshirt_painting?.customization_offer?.variationIds && item.tshirt_painting?.customization_offer?.variationIds.length > 0) {
        this.data.TShirtPrinting.data.size = _.find(
          item.tshirt_painting?.customization_offer?.offer?.d_m_customizing_variants,
          {id: item.tshirt_painting?.customization_offer?.variationIds[0]})
      }
    }
  }

  if(item.leather_stamping) {
    if(item.leather_stamping?.need_leather_stampings) {
      this.data.engravingAdminCheck = item.leather_stamping.complete === 1
    }
    this.data.LeatherStamp.data.isLeatherStamp = Boolean(item.leather_stamping?.need_leather_stampings)
    this.data.LeatherStamp.data.stampingLine = item.leather_stamping?.stamping_line
    this.data.LeatherStamp.data.resultCost = parseFloat(item.leather_stamping?.cost || 0).toFixed(2)

    if(item.leather_stamping?.customization_offer) {
      this.data.LeatherStamp.data.customization = item.leather_stamping?.customization_offer?.offer
    }
  }

  if(item.photo_print) {
    if(item.photo_print?.need_photo_print) {
      this.data.engravingAdminCheck = item.photo_print.complete === 1
    }
    this.data.PhotoPrint.data.isPhotoPrint = Boolean(item.photo_print?.need_photo_print)
    this.data.PhotoPrint.data.quantity = item.photo_print?.quantity
    this.data.PhotoPrint.data.resultCost = parseFloat(item.photo_print?.cost || 0).toFixed(2)

    if(item.photo_print?.customization_offer) {
      this.data.PhotoPrint.data.customization = item.photo_print?.customization_offer?.offer
    }

    if(item.photo_print?.customization_size_offer) {
      this.data.PhotoPrint.data.size = item.photo_print?.customization_size_offer?.offer
    }
  }

  if(item.vat_tax) {
    this.data.visibleVatNumber = item['vat_tax']['use_vat_id'] === 1
    this.data.isVatNumberByCountry = true
    this.data.vatNumber = item['vat_tax']['vat_tax']
  } else {
    this.data.visibleVatNumber = false
    this.data.isVatNumberByCountry = false
  }
  this.data.vatNumberChoose = true

  if(item?.addition_info?.info && item.addition_info?.info?.tax_clearance) {
    this.data.transactionAmount = item.addition_info?.info?.tax_clearance?.total_sum
    this.data.customsClearance = item.addition_info?.info?.tax_clearance?.cost
  }

  if(this.data.transactionAmount === 0 && !item?.addition_info?.info && item.payment_transaction){
    this.data.transactionAmount = item.payment_transaction.warehouse_amount
  }

  if(item.payment_transaction){
    this.data.Order.setIssetTransaction(true)
  }

  if(item.order_ioss) {
    this.data.IOSS = item.order_ioss
    this.data.marketplace = _.find(this.data.marketplaces, {name: item.order_ioss?.marketplace})
  }


  this.data.DeliveryPayPal.setDeliveryItem({
    address: item['recipient_address'],
    address2: item['recipient_address_1'],
    city: item['recipient_city'],
    region: item['recipient_region'],
    country: {
      id: item['recipient_country']['id'],
      label: item['recipient_country']['name'],
    },
    zip: item['recipient_zip_code']
  })

  this.data.Personal.setPersonalItem({
    personalName: item['recipient_contact_name'],
    personalPhone: item['recipient_phone_number'],
    personalCompany: item['recipient_company_name'],
    personalEmail: item['recipient_email'],
  })

  // Add count proform products with response data
  if(Object.keys(proform).length > 1){
    Object.keys(proform).map(() => {
      // if only one product
      if(Object.keys(proform).length === proformProducts.length) return
      this.addProformProduct(true)
    })
  }

  // set proform product data & selected value
  Object.keys(proform).map((item, index) => {
    proformProducts[index].setOrderProformItem(proform[item], item, isAdmin)
  })

  // set HS Codes in proform products
  proformProducts.map(item => {
    let entityItem = _.find(proformEntities, {id: parseInt(item.getProformEntityId())})
    if(entityItem){
      item.setHSCode(entityItem['hs_code'])
    }
  })

  /**
   * set ProductsForCellArray
   */
  this.data.productsForCellArray = []
  this.setProductsForCellArray(productEntities, val)

  // productEntities.map((item, index) => {
  //   if(index > 0){
  //     this.addProduct()
  //   }
  //
  //   this.data.Products[index].setProduct(item.product, {
  //     id: item.product.id,
  //     name: item.product.name,
  //     description: item.item_description,
  //     count: item.item_quantity,
  //     price: item.item_price,
  //     item_total: item.item_total,
  //     sku_code: item.product.sku_code,
  //     productModelId: item.id,
  //   })
  //
  // })

  /**
   * Set User dimensions
   */
  if(Object.keys(item?.order_dimensions).length > 0){
    this.data.Dimensions.setDimensionsHeight(item?.order_dimensions[0]?.height || '')
    this.data.Dimensions.setDimensionsWidth(item?.order_dimensions[0]?.width || '')
    this.data.Dimensions.setDimensionsLength(item?.order_dimensions[0]['length'] || '')
    if (isAdmin) {
      this.data.Dimensions.setDimensionsWeightLB(item?.order_dimensions[0]?.imperial?.weight_lb || '')
      this.data.Dimensions.setDimensionsWeightOZ(item?.order_dimensions[0]?.imperial?.weight_oz || '')
    } else {
      this.data.Dimensions.setDimensionsWeightLB(item?.order_dimensions[0]?.linear?.weight_kg || '')
      this.data.Dimensions.setDimensionsWeightOZ(item?.order_dimensions[0]?.linear?.weight_g || '')
    }
  }

  /**
   * Set Admin dimensions
   */
  if(item.admin_dimension){
    this.data.AdminDimensions.setDimensionsHeight(item?.admin_dimension?.height ? parseFloat(item?.admin_dimension?.height).toFixed(2) : '')
    this.data.AdminDimensions.setDimensionsWidth(item?.admin_dimension?.width ? parseFloat(item?.admin_dimension?.width).toFixed(2) : '')
    this.data.AdminDimensions.setDimensionsLength(item?.admin_dimension['length'] ? parseFloat(item?.admin_dimension['length']).toFixed(2) : '')
    this.data.AdminDimensions.setDimensionsWeightLB(item?.admin_dimension?.imperial?.weight_lb && item?.admin_dimension?.imperial?.weight_lb % 1 === 0 ? item?.admin_dimension?.imperial?.weight_lb : item?.admin_dimension?.imperial?.weight_lb ? parseFloat(item?.admin_dimension?.imperial?.weight_lb).toFixed(2) : '')
    this.data.AdminDimensions.setDimensionsWeightOZ(item?.admin_dimension?.imperial?.weight_oz && item?.admin_dimension?.imperial?.weight_oz % 1 === 0 ? item?.admin_dimension?.imperial?.weight_oz : item?.admin_dimension?.imperial?.weight_oz ? parseFloat(item?.admin_dimension?.imperial?.weight_oz).toFixed(2) : '')
  }

  // if(isAdmin && item?.admin_dimension){
  //
  // } else {
  //   if(item.order_dimensions && item.order_dimensions.length > 0){
  //
  //   }
  // }

  this.setSendFromRegion(item?.send_from_region ? item?.send_from_region : '')
  this.setExpressTrack(item?.external_lot_number || '')
  this.setNPTrack(item?.user_tracking_number || '')

  this.data.sendFrom = item.send_from

  this.data.userTrackingNumber = item.user_tracking_number
}



OrderFBM.prototype.setProductsForCellArray = function (productEntities, item) {
  productEntities.map((productEntity) => {

    let {
      newProduct,
      cells,
      existCell
    } = this.createProductModelAndCellsArray(productEntity, item)

    /**
     * set product to ProductForCell model
     * and add to productsForCellArray array
     */
    let newIndex = this.addProductForCells(newProduct, cells, productEntity)

    /**
     * Add Product Count Without Cell
     */
    this.addProductCountWithoutCell(productEntity, this.data.productsForCellArray[newIndex])

    /**
     * Add Product Exist Count Without Cell
     */
    this.addProductExistCountWithoutCell(productEntity, this.data.productsForCellArray[newIndex])

    /**
     * Sort ProductsForCellArray by storage quantity
     */
    if(!existCell){
      return
      // let userArray = this.data.productsForCellArray[newIndex].data.UserCellArray
      // this.data.productsForCellArray[newIndex].data.UserCellArray = this.sortCellArrayByStorageCount(userArray)
    }

  })
}

OrderFBM.prototype.setConsolidationOrderUnionId = function (val) {
  this.data.consolidationOrderUnionId = val
}

OrderFBM.prototype.setConsolidationUnion = function (val) {
  this.data.consolidationUnion = val
}

OrderFBM.prototype.setConsolidationOrderUnionTrack = function (val) {
  this.data.consolidationOrderUnionTrack = val
}

OrderFBM.prototype.setRegions = function (val) {
  this.data.regions = val
}

OrderFBM.prototype.setSendFromRegion = function (val) {
  this.data.sendFromRegion = val
}

OrderFBM.prototype.setExpressTrack = function (val) {
  this.data.expressTrack = val
}

OrderFBM.prototype.setNPTrack = function (val) {
  this.data.npTrack = val
}