<template>
  <div class="user-card">


    <div class="user-card__img">
      <div class="user-card__img-i">
        <img v-if="user.userAvatar.id"
             :src="`data:${user.userAvatar.mimeType};base64,${user.userAvatar.base64}`" alt="img">
        <img v-else style="background: #ce9e64" src="/img/profile-group/profile-photo-default.svg" alt="img">
      </div>
    </div>
    <div class="user-card__content">
      <div class="user-card__head">
        <div class="user-card__head-info">
          <div class="custom-row">
            <div class="custom-col custom-col--50 mb-0">
              <div class="user-card__head-name" v-if="Object.keys(FBM.data.User.user).length > 0">
                <ValueHelper
                    v-if="FBM.data.User.user && FBM.data.User.user.contacts"
                    :value="FBM.data.User.user"
                    :deep="'contacts.0.user_full_name'"
                />
                <ValueHelper
                    v-else
                    :value="FBM.data.User.user"
                    :deep="'user_personal_contact.user_full_name'"
                />
              </div>
            </div>
            <div class="custom-col custom-col--50 mb-0">
              <div v-if="FBM.data.Order && FBM.data.Order.data.id" class="user-card__head-name text-right pr-4">
                <span style="margin-right: -3px;">#</span><ValueHelper
                    :value="FBM.data.Order.data.id"
                />
              </div>
            </div>
          </div>

          <div class="user-card__head-row" v-if="!isISubUser">
            <div class="amount-row light">
              Balance:
              <span class="color-red important font-weight-bold m-0">
                $ {{FBM.data.User.user.balance}}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="site-link brown text-decoration-underline mt-1">
        <div v-if="isAdmin && FBM.data.Order.data.historyOfChanged"
             class="mb-3"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>
          <div class="site-link text-decoration-underline btn-style brown"
               @click="historyOfChangesFunc('fbm', FBM.data.Order.data.historyOfChanged)">
            {{$t('common_CheckHistory.localization_value.value')}}
          </div>
        </div>
      </div>

      <div class="user-card__body">
        <div class="block-table">
          <div class="block-table__row mb-0">
            <div class="block-table__col mb-3">
              <TitleHorizontal
                  class="black mb-0"
                  :value="$t('fbm_CustomerInformation.localization_value.value')"
                  :rightBtn="true"
                  :rightBtnType="'edit'"
                  :rightBtnText="'Edit'"
                  @rightBtnClick="changeInformationDataPopup()"
              />
            </div>
            <div class="block-table__col mb-3"
                 v-bind:class="{'ui-no-valid': personal.validation.personalName}"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_ContactName'])"></div>
                {{$t('fbm_ContactName.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                        :value="personal.information.personalName"
                />
              </div>
              <span class="color-orange-validation mt-2" v-if="personal.validation.personalName &&
                $t(`${personal.validationTranslate.personalName}.localization_value.value`)">
                  {{$t(`${personal.validationTranslate.personalName}.localization_value.value`)}}
              </span>
            </div>
            <div class="block-table__col mb-3"
                 v-bind:class="{'ui-no-valid': personal.validation.personalCompany}"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_CompanyName'])"></div>
                {{$t('fbm_CompanyName.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                        :value="personal.information.personalCompany"
                />
              </div>
              <span class="color-orange-validation mt-2" v-if="personal.validation.personalCompany &&
                $t(`${personal.validationTranslate.personalCompany}.localization_value.value`)">
                  {{$t(`${personal.validationTranslate.personalCompany}.localization_value.value`)}}
              </span>
            </div>
            <div class="block-table__col mb-3"
                 v-bind:class="{'ui-no-valid': personal.validation.personalEmail}"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_Email'])"></div>
                {{$t('fbm_Email.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                        :value="personal.information.personalEmail"
                />
              </div>
              <span class="color-orange-validation mt-2" v-if="personal.validation.personalEmail &&
                $t(`${personal.validationTranslate.personalEmail}.localization_value.value`)">
                  {{$t(`${personal.validationTranslate.personalEmail}.localization_value.value`)}}
              </span>
            </div>

            <div class="block-table__col mb-3"
                 v-bind:class="{'ui-no-valid': personal.validation.personalPhone}"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_PhoneNumber'])"></div>
                {{$t('fbm_PhoneNumber.localization_value.value')}}
              </div>
              <div class="block-table__content word-break">
                <ValueHelper
                        :value="personal.information.personalPhone"
                />
              </div>
              <span class="color-orange-validation mt-2" v-if="personal.validation.personalPhone &&
                $t(`${personal.validationTranslate.personalPhone}.localization_value.value`)">
                  {{$t(`${personal.validationTranslate.personalPhone}.localization_value.value`)}}
              </span>
            </div>

            <div class="block-table__col mb-3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fbm_Address'])"></div>
              <TitleHorizontal
                  class="black mb-0"
                  :value="$t('fbm_Address.localization_value.value')"
                  :rightBtn="true"
                  :rightBtnType="'edit'"
                  :rightBtnText="'Edit'"
                  @click.native="changeDeliveryDataPopup()"
              />
            </div>
            <div class="block-table__col mb-3"
                 v-bind:class="{'ui-no-valid': deliveryFBM.validation.address}"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_Address'])"></div>
                {{$t('fbm_Address.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                    :value="deliveryFBM.delivery.address"
                />
              </div>
              <span class="color-orange-validation mt-2" v-if="deliveryFBM.validation.address &&
                $t(`${deliveryFBM.validationTranslate.address}.localization_value.value`)">
                  {{$t(`${deliveryFBM.validationTranslate.address}.localization_value.value`)}}
              </span>
            </div>
            <div class="block-table__col mb-3"
                 v-bind:class="{'ui-no-valid': deliveryFBM.validation.address2}"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_addressSecond'])"></div>
                {{$t('common_addressSecond.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                    :value="deliveryFBM.delivery.address2"
                />
              </div>
              <span class="color-orange-validation mt-2" v-if="deliveryFBM.validation.address2 &&
                $t(`${deliveryFBM.validationTranslate.address2}.localization_value.value`)">
                  {{$t(`${deliveryFBM.validationTranslate.address2}.localization_value.value`)}}
              </span>
            </div>
            <div class="block-table__col block-table__col--50 mb-3"
                 v-bind:class="{'ui-no-valid': deliveryFBM.validation.city}"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_City'])"></div>
                {{$t('fbm_City.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                        :value="deliveryFBM.delivery.city"
                />
              </div>
              <span class="color-orange-validation mt-2" v-if="deliveryFBM.validation.city &&
                $t(`${deliveryFBM.validationTranslate.city}.localization_value.value`)">
                  {{$t(`${deliveryFBM.validationTranslate.city}.localization_value.value`)}}
              </span>
            </div>
            <div class="block-table__col block-table__col--50 mb-3"
                 v-bind:class="{'ui-no-valid': deliveryFBM.validation.region}"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_Region'])"></div>
                {{$t('fbm_Region.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                        :value="deliveryFBM.delivery.region"
                />
              </div>
              <span class="color-orange-validation mt-2" v-if="deliveryFBM.validation.region &&
                $t(`${deliveryFBM.validationTranslate.region}.localization_value.value`)">
                  {{$t(`${deliveryFBM.validationTranslate.region}.localization_value.value`)}}
              </span>
            </div>

            <div class="block-table__col block-table__col--50 mb-3"
                 v-bind:class="{'ui-no-valid': deliveryFBM.validation.zip}"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_ZipCode'])"></div>
                {{$t('fbm_ZipCode.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                        :value="deliveryFBM.delivery.zip"
                />
              </div>
              <span class="color-orange-validation mt-2" v-if="deliveryFBM.validation.zip &&
                $t(`${deliveryFBM.validationTranslate.zip}.localization_value.value`)">
                  {{$t(`${deliveryFBM.validationTranslate.zip}.localization_value.value`)}}
              </span>
            </div>
            <div class="block-table__col block-table__col--50 mb-3"
                 v-bind:class="{'ui-no-valid': deliveryFBM.validation.country}"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_Country', 'express_ViewCustomLimits'])"></div>
                {{$t('fbm_Country.localization_value.value')}}
              </div>

              <div class="block-table__content word-break">
                <ValueHelper
                    :value="deliveryFBM.delivery.country"
                    :deep="'label'"
                />
              </div>
              <span class="color-orange-validation mt-2" v-if="deliveryFBM.validation.country &&
                $t(`${deliveryFBM.validationTranslate.country}.localization_value.value`)">
                  {{$t(`${deliveryFBM.validationTranslate.country}.localization_value.value`)}}
              </span>
              <div class="site-link site-link-alt mt-3 wfc d-flex"
                   v-if="FBM.data.DeliveryPayPal.delivery.country !== ''"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                        'express_ViewCustomLimits',
                      ])"></div>
                <div
                    @click="showCustomLimitsPopup(FBM.getTrsnAddressCountry().id, 'isModalCustomLimitsPopup')"
                >
                  {{$t('express_ViewCustomLimits.localization_value.value')}}
                </div>
              </div>
            </div>

            <div class="block-table__col mb-3">
              <TitleHorizontal
                  class="black mb-0"
                  :value="$t('fbm_CommentOptional.localization_value.value')"
                  :rightBtn="true"
                  :rightBtnType="'edit'"
                  :rightBtnText="'Edit'"
                  @rightBtnClick="changeCommentDataPopup()"
              />
            </div>

            <div class="block-table__col mb-3">
              <div class="block-table__content word-break">
                <ValueHelper
                        :value="FBM.data.comment"
                />
              </div>
            </div>

          </div>

        </div>


      </div>

      <!--<ChangePasswordPopup-->
        <!--v-if="isModalChangePasswordPopup"-->
        <!--@close="changeChangePasswordPopup(false)"-->
      <!--/>-->


      <!--<PhonesPopup-->
          <!--v-if="isModalPhonesPopup"-->
          <!--@close="changePhonesPopup(false)"-->
      <!--/>-->

      <ChangeDataPopup
          v-if="isModalChangeAddressPopup"
          :items="changeDataPopup"
          :popupTitle="popupTitle"
          :getRegionsIfUSA="true"
          @save="saveOrderData"
          @close="isModalChangeAddressPopup = false"
      />

      <CustomLimitsPopup
          @close="isModalCustomLimitsPopup = false"
          v-if="isModalCustomLimitsPopup"
      />

      <!--<HistoryOfChanges-->
          <!--v-if="isModalHistoryOfChanges"-->
          <!--@close="changeHistoryOfChanges(false)"-->
      <!--/>-->

    </div>
  </div>
</template>

<script>
  import TitleHorizontal from "../../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
  import ChangeDataPopup from "../../../../popups/ChangeDataPopup/ChangeDataPopup";
  import {PersonalInformation} from "../../../../../../globalModels/PersonalInformation";
  import {FBMMixinsDeliveryData, FBMMixinsHelper} from "../../../../../../../mixins/FBMMixins/FBMMixins";
  import {Delivery} from "../../../../../../globalModels/Delivery";
  import {Comment} from "../../../../models/Comment";
  import {GiftMassage} from "../../../../models/GiftMassage";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import {consolidationMixin} from "@/mixins/consolidationMixins/consolidationMixin";
  import {customLimitsMixin} from "@/mixins/commonMixins/customLimitsMixin";
  import CustomLimitsPopup from "@/components/modules/OrdersFBMModule/popups/_CustomLimitsPopup/CustomLimitsPopup";

  export default {
    name: "OrderCard",
    components:{
      CustomLimitsPopup,
      ValueHelper,
      TitleHorizontal,
      ChangeDataPopup,
    },

    mixins: [FBMMixinsDeliveryData, FBMMixinsHelper, consolidationMixin, customLimitsMixin],

    props:{
      FBM: Object,
      user: Object,
      personal: Object,
      deliveryFBM: Object,
    },

    computed: {
      getGiftImg() {
        let img = false

        if(this._.has(this.FBM.data.giftPaper, 'small_image_file')){
          img = 'data:' + this.FBM.data.giftPaper.small_image_file.mime_type + ';base64,' + this.FBM.data.giftPaper.imageBase64
        }

        return img
      }
    },

    data(){
      return{
        isModalChangePasswordPopup: false,
        isModalPhonesPopup: false,
        isModalChangeAddressPopup: false,
        isModalHistoryOfChanges: false,
        isModalCustomLimitsPopup: false,

        changeDataPopup: [],

        typeFieldsChanges: null,
        popupTitle: '',


        /**
         * Fake models for check validation on Change Data Popup
         */
        PI: new PersonalInformation(),
        Delivery: new Delivery(),
        Comment: new Comment(),
        Gift: new GiftMassage()

      }
    },

    methods: {
      changeChangePasswordPopup(val){
        this.isModalChangePasswordPopup = val
      },

      changePhonesPopup(val){
        this.isModalPhonesPopup= val
      },

      changeChangeDataPopup(val){
        this.isModalChangeAddressPopup = true
        this.changeDataPopup = val
      },

      changeHistoryOfChanges(val){
        this.isModalHistoryOfChanges = val
      },

      changeInformationDataPopup(){

        this.PI.setPersonalName(this.personal.information.personalName)
        this.PI.setPersonalPhone(this.personal.information.personalPhone)
        this.PI.setPersonalCompany(this.personal.information.personalCompany)
        this.PI.setPersonalEmail(this.personal.information.personalEmail)

        this.setPopupInformationData()
        this.typeFieldsChanges = 'personal'
        this.popupTitle = 'common_personalChanges'
        this.isModalChangeAddressPopup = true
      },

      changeDeliveryDataPopup(){

        this.Delivery.setAddress(this.deliveryFBM.delivery.address)
        this.Delivery.setAddress2(this.deliveryFBM.delivery.address2)
        this.Delivery.setCity(this.deliveryFBM.delivery.city)
        this.Delivery.setRegion(this.deliveryFBM.delivery.region)
        this.Delivery.setCountry(this.deliveryFBM.delivery.country)
        this.Delivery.setZip(this.deliveryFBM.delivery.zip)

        this.setPopupDeliveryData()
        this.typeFieldsChanges = 'delivery'
        this.popupTitle = 'common_deliveryChanges'
        this.isModalChangeAddressPopup = true
      },

      changeGiftDataPopup(){

        this.Gift.setGiftMassage(this.FBM.getGiftMassage())
        this.Gift.setGiftPaper(this.FBM.data.giftPaper)

        this.setPopupGiftData()
        this.typeFieldsChanges = 'gift'
        this.popupTitle = 'common_giftChanges'
        this.isModalChangeAddressPopup = true
      },

      changeCommentDataPopup(){

        this.Comment.setComment(this.FBM.data.comment)

        this.setPopupCommentData()
        this.typeFieldsChanges = 'comment'
        this.popupTitle = 'common_commentChanges'
        this.isModalChangeAddressPopup = true
      },

      changeAPIDataPopup(){

        this.setPopupAPIData()
        this.typeFieldsChanges = 'api'
        this.popupTitle = 'common_apiChanges'
        this.isModalChangeAddressPopup = true
      },

      changeConsolidationDataPopup(){

        this.setPopupConsolidationData()
        this.typeFieldsChanges = 'consolidation'
        this.popupTitle = 'fbm_apc'
        this.isModalChangeAddressPopup = true
      },

      saveOrderData(data) {
        switch (this.typeFieldsChanges) {
          case 'personal':
            this.personalUpdate(data)
            break

          case 'delivery':
            this.deliveryUpdate(data)
            break

          case 'gift':
            this.giftUpdate(data)
            break

          case 'comment':
            this.commentUpdate(data)
            break

          case 'api':
            this.APIUpdate(data)
            break

          case 'consolidation':
            this.consolidationUpdate(data)
            break
        }
      },



    }
  }
</script>

<style lang="scss" scoped>
.ui-no-valid {
  .color-orange-validation {
    font-size: 11px;
    line-height: 15px;
  }

  .block-table__content, .block-table__label {
    color: #F2994A;
  }
}
</style>
