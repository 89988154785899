import { render, staticRenderFns } from "./OrdersFBMCreationPage.vue?vue&type=template&id=64ec8d62&scoped=true"
import script from "./OrdersFBMCreationPage.vue?vue&type=script&lang=js"
export * from "./OrdersFBMCreationPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64ec8d62",
  null
  
)

export default component.exports