<template>
  <modal
      @close="$emit('close')"
      class="custom-popup order-details-popup"
      v-if="loading"
  >
    <template slot="header">

      <div
          class="ws-initial"
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['logInfo_OrderDetails'])"></div>
        {{ $t('logInfo_OrderDetails.localization_value.value') }}
        <template v-if="trackingNumber">
          {{ trackingNumber }}
        </template>
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div v-if="noData">{{ $t('logInfo_noData.localization_value.value') }}</div>

        <div class="simple-table">
          <div class="simple-table__inner">


            <table v-if="!noData">
              <tr>
                <th colspan="2">
                  <div
                      v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['logInfo_Currently'])"></div>
                    {{ $t('logInfo_Currently.localization_value.value') }}
                  </div>
                </th>
              </tr>
              <tr v-if="currently">
                <td>
                  <div class="site-status d-flex">
                    <span class="tracking-name">
                      {{ currently.eventTime | moment("DD MMM, YYYY") }}
                      {{ $t('common_at.localization_value.value') }}
                      {{ currently.eventTime | moment("HH:mm") }}
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <template v-if="currently.location">
                      {{ currently.location }};
                      <br>
                    </template>
                    <template v-if="currently.message">
                      {{ currently.message }}
                    </template>
                  </div>
                </td>
              </tr>
              <tr>
                <th colspan="2">
                  <div
                      v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['logInfo_History'])"></div>
                    {{ $t('logInfo_History.localization_value.value') }}
                  </div>
                </th>
              </tr>
              <tr v-for="(item, index) in history"
                  :key="index"
              >
                <td>
                  <div class="site-status d-flex">
                    <span class="tracking-name">
                      {{ item.eventTime | moment("DD MMM, YYYY") }}
                      {{ $t('common_at.localization_value.value') }}
                      {{ item.eventTime | moment("HH:mm") }}
                    </span>
                  </div>
                </td>
                <td>
                  <template v-if="currently.location">
                    {{ item.location }};
                    <br>
                  </template>
                  <template v-if="currently.message">
                    {{ item.message }}
                  </template>
                </td>
              </tr>
            </table>
          </div>
        </div>

      </div>
    </template>
  </modal>

</template>

<script>

import Modal from "@/components/commonModals/Modal";

export default {
  name: "ExpressPoshtaTrackingPopup",

  components: {
    Modal,
  },

  props: {
    trackingNumber: [String, Number],
  },

  data() {
    return {
      noData: false,
      currently: null,
      history: [],
      loading: false,
    }
  },

  mounted() {
    if (!this.trackingNumber) {
      this.noData = true
      this.loading = true
      return
    }

    this.$store.dispatch('getExpressPoshtaTracking', this.trackingNumber).then(response => {
      this.loading = true
      let data = this.getRespData(response)

      if(!data || (data && data.length === 0)) {
        this.noData = true
        return
      }

      this.currently = this._.last(data)
      this.history = data
    })

  }


}

</script>

<style scoped>

.ws-initial {
  white-space: initial;
}

.tracking-name {
  position: relative;
  top: 2px;
}

.simple-table th, .simple-table td {
  white-space: initial;
}

.simple-table {
  margin-left: -48px;
  margin-right: -40px;
}

.simple-table__inner th:first-child,
.simple-table__inner td:first-child {
  padding-left: 48px;
}

.simple-table__inner th:last-child,
.simple-table__inner td:last-child {
  padding-right: 40px;
}

.order-details-popup {
  z-index: 999;
}

@media (max-width: 768px) {
  .simple-table {
    margin-left: -15px;
    margin-right: -15px;
  }

  .simple-table__inner th:first-child,
  .simple-table__inner td:first-child {
    padding-left: 15px;
  }

  .simple-table__inner th:last-child,
  .simple-table__inner td:last-child {
    padding-right: 15px;
  }
}

</style>
