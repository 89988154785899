<template>
  <div class="detail-cards">
    <div class="detail-cards__inner">
      <div class="detail-cards__content">
        <div class="detail-cards__section">
          <AdminFieldsBlock
                  :FBM="FBM"
                  @reload="$emit('reloadEditPage', false, false)"
          />
        </div>
<!--        <div class="detail-cards__section" v-if="isWarehouse">-->
<!--          <ScannerInKiev-->
<!--                  :FBM="FBM"-->
<!--          />-->
<!--        </div>-->
        <div class="detail-cards__section" v-if="!isLabel">
          <Scanner
                  :FBM="FBM"
                  :products="FBM.data.Products"
                  @recalculateFees="recalcProductsExtraFee()"
                  @recalculateOrderFee="recalculateOrderFee"
          />
        </div>
        <div class="detail-cards__section">
          <OrderDetails
                  :FBM="FBM"
                  :proformProducts="FBM.data.ProformProducts"
                  :keyReload="keyReload"
                  @removeProformProduct="index => $emit('removeProformProduct', index)"
                  @changeCategory="({val, nameVal, item}) => changeCategoryProform({val, nameVal, item}, FBM.getTrsnAddressCountry(), true)"
                  @changeProformSelectItem="item => changeProformSelectItem(item, FBM.getTrsnAddressCountry())"
                  @reCreateLabel="$emit('prepareSaveFBM', {draft: false, edit: true, createLabel: true, adminManage: true})"
                  @reload="$emit('reloadEditPage', false, false)"
          />
        </div>
        <div class="detail-cards__section detail-cards__section--btn">
          <div class="detail-cards__section-col">
            <router-link :to="$store.getters.GET_PATHS.ordersFBM">
              <span class="site-link site-link--alt brown">
              {{$t('common_cancel.localization_value.value')}}
              </span>
            </router-link>
          </div>
          <div class="detail-cards__section-col">
            <MainButton
                    class="sharp"
                    :value="$t('common_update.localization_value.value')"
                    @click.native="$emit('prepareSaveFBM', {draft: false, edit: true, createLabel: false, adminManage: true})"
                    v-bind:class="{'disabled-btn' : $store.getters.getOrdersFBMBtn}"
            />
          </div>
        </div>
      </div>
      <div class="detail-cards__aside">
        <div class="detail-cards__aside-inner">
          <OrderCard
                  :FBM="FBM"
                  :user="FBM.data.User"
                  :personal="FBM.data.Personal"
                  :deliveryFBM="FBM.data.DeliveryPayPal"
                  @reload="reload"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import {proformMixins} from "../../../../../../mixins/proformMixins/proformMixins";
  import {FBMMixins} from "../../../../../../mixins/FBMMixins/FBMMixins";
  import OrderDetails from "../../components/FBMAdminEditBlocks/OrderDetails/OrderDetails";
  import OrderCard from "../../components/FBMAdminEditBlocks/OrderCard/OrderCard";
  import Scanner from "../../components/FBMAdminEditBlocks/Scanner/Scanner";
  import AdminFieldsBlock from "../../components/FBMAdminEditBlocks/AdminFieldsBlock/AdminFieldsBlock";
  // import ScannerInKiev
  //   from "@/components/modules/OrdersFBMModule/components/components/FBMAdminEditBlocks/ScannerInKiev/ScannerInKiev";

  export default {
    name: "OrdersFBMEditingPageAdmin",
    components: {
      // ScannerInKiev,
      AdminFieldsBlock,
      OrderCard,
      OrderDetails,
      Scanner,
      MainButton,
    },

    mixins: [proformMixins, FBMMixins],

    props: {
      FBM: Object,
    },

    computed: {
      getAdminDimensionsWidth() {
        return this.FBM.data.AdminDimensions.data.width
      },
      getAdminDimensionsHeight() {
        return this.FBM.data.AdminDimensions.data.height
      },
      getAdminDimensionsLength() {
        return this.FBM.data.AdminDimensions.data.dimensionLength
      },
    },

    watch: {
      getAdminDimensionsWidth() {
        this.recalculateOrderFee()
      },
      getAdminDimensionsHeight() {
        this.recalculateOrderFee()
      },
      getAdminDimensionsLength() {
        this.recalculateOrderFee()
      },
    },

    mounted() {

    },

    data() {
      return {
        keyReload: -1,
      }
    },

    methods: {

      showTransactionInfo() {
        this.isModalTransactionInfo = true
      },

      closeTransactionInfo() {
        this.isModalTransactionInfo = false
      },

      // changeUser(user) {
      //   this.FBM.setUser(user)
      //   this.FBM.resetProduct()
      // },

      reload() {
        this.keyReload = Math.random()
      },

      recalcProductsExtraFee() {
        this.FBM.setProductsExtraFee(0)
        let countProductsForFee = 0
        this.FBM.data.productsForCellArray.forEach(prItem => {
          if (prItem.data?.Product?.product) {
            countProductsForFee += prItem.data.Product.product.count
          }
        })

        if (countProductsForFee > 3) {
          let prepData = {
            'id': this.FBM.data.Order.getId() ? this.FBM.data.Order.getId() : null,
            'product_count': countProductsForFee,
            'user_id': this.FBM.data.User.getUserId()
          }
          this.$store.dispatch('getOrdersFBMProductFee', prepData).then(feeResponse => {
            if (feeResponse?.data?.data?.product_process) {
              this.FBM.setProductsExtraFee(feeResponse?.data?.data?.product_process)
            }
          }).catch(error => this.createErrorLog(error))
        }
      },

      recalculateOrderFee() {
        this.FBM.setOrderPrice(this.calculateOrderFee)
      },

    },


  }
</script>

<style scoped>

</style>
